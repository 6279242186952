.frame-container {
  overflow-y: auto;
  max-width: 100vw;
  max-height: 100vh;
}

.explorer-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.explorer-item {
  padding: 0.5rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 18%;
  height: 18%;
  margin: 2%;
}

.explorer-item-icon {
  width: 45px;
  height: 45px;
}

@media screen and (min-width: 400px) {
  .frame-container {
    max-width: 400px;
  }
}
